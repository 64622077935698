import React from "react";
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { Link } from 'react-router-dom';

class Error extends React.Component {
  render() {
    return (
			<div className="vg-container-tight">
      <Alert show={this.props.number==1} variant="danger">
        <Alert.Heading>Event nicht gefunden</Alert.Heading>
        <p>
					Wir haben überall nachgeschaut, konnten aber kein Event mit dem
					Code <span style={{fontWeight: 'bold'}}>{this.props.eventId || "?"}</span> finden.
        </p>
        <hr />
        <div className="d-flex justify-content-end">
          <Link to="/"><Button variant="primary">
            Zurück zur Startseite
          </Button></Link>
        </div>
      </Alert>

      <Alert show={this.props.number == 2} variant="danger">
        <Alert.Heading>Kommunikationsfehler</Alert.Heading>
        <p>
					Wir konnten nicht mit unseren Servern kommunizieren. Stelle sicher,
          dass du mit dem Internet verbunden bist.
        </p>
        <hr />
        <div className="d-flex justify-content-end">
          <Link to="/" className="mr-2"><Button variant="outline-secondary">
            Zurück zur Startseite
          </Button></Link>
          <a href={window.location}><Button variant="primary">
            Seite neu laden
          </Button></a>
        </div>
      </Alert>

      <Alert show={this.props.number == 3} variant="danger">
        <Alert.Heading>Upload fehlgeschlagen</Alert.Heading>
        <p>
          Beim Hochladen deines Fotos ist ein Fehler aufgetreten. Bitte versuche
          es erneut.
        </p>
        <hr />
        <div className="d-flex justify-content-end">
          <Link to="/" className="mr-2"><Button variant="outline-secondary">
            Zurück zur Startseite
          </Button></Link>
          <a href={window.location}><Button variant="primary">
            Seite neu laden
          </Button></a>
        </div>
      </Alert>

      <Alert show={this.props.number == 4} variant="danger">
        <Alert.Heading>Löschen fehlgeschlagen</Alert.Heading>
        <p>
          Dein Foto konnte nicht gelöscht werden. Bitte versuche
          es erneut.
        </p>
        <hr />
        <div className="d-flex justify-content-end">
          <Link to="/" className="mr-2"><Button variant="outline-secondary">
            Zurück zur Startseite
          </Button></Link>
          <a href={window.location}><Button variant="primary">
            Seite neu laden
          </Button></a>
        </div>
      </Alert>

      <Alert show={this.props.number == 5} variant="danger">
        <Alert.Heading>Upload fehlgeschlagen</Alert.Heading>
        <p>
          Wir konnten dein Foto nicht öffnen. Bitte versuche es erneut.
        </p>
        <hr />
        <div className="d-flex justify-content-end">
          <Link to="/" className="mr-2"><Button variant="outline-secondary">
            Zurück zur Startseite
          </Button></Link>
          <a href={window.location}><Button variant="primary">
            Seite neu laden
          </Button></a>
        </div>
      </Alert>

      <Alert show={this.props.number == 6} variant="danger">
        <Alert.Heading>Kein Zugriff</Alert.Heading>
        <p>
          Du bist nicht berechtigt dieses Event zu verwalten.
        </p>
        <hr />
        <div className="d-flex justify-content-end">
          <Link to="/" className="mr-2"><Button variant="outline-secondary">
            Zurück zur Startseite
          </Button></Link>
          <a href={window.location + "/.." }><Button variant="primary">
            Zurück zum Event
          </Button></a>
        </div>
      </Alert>

      <Alert show={this.props.number == 7} variant="danger">
        <Alert.Heading>Speichern der Änderungen fehlgeschlagen</Alert.Heading>
        <p>
          Beim Speichern der Änderungen ist ein Fehler aufgetreten. Bitte
          versuche es erneut.
        </p>
        <hr />
        <div className="d-flex justify-content-end">
          <Link to="/" className="mr-2"><Button variant="outline-secondary">
            Zurück zur Startseite
          </Button></Link>
          <a href={window.location}><Button variant="primary">
            Seite neu laden
          </Button></a>
        </div>
      </Alert>

      <Alert show={this.props.number > 7} variant="danger">
        <Alert.Heading>Unbekannter Fehler</Alert.Heading>
        <p>
          Beim Laden der Seite ist ein unbekannter Fehler aufgetreten.
        </p>
        <hr />
        <div className="d-flex justify-content-end">
          <Link to="/"><Button variant="primary">
            Zurück zur Startseite
          </Button></Link>
        </div>
      </Alert>

    </div>)

  }

}

export default Error;
