import React, { useRef, useEffect} from "react";
import { Link } from "react-router-dom";
import logo from "../img/logo.svg";
import icon from "../img/icon.svg";
import { Nav, Navbar, NavDropdown } from 'react-bootstrap'

const ConditionalWrapper = ({ condition, wrapper, children }) => 
  condition ? wrapper(children) : children;

// get the sticky element
const stickyElm = document.querySelector('header')

const observer = new IntersectionObserver( 
    ([e]) => e.target.classList.toggle('scrolled', e.intersectionRatio < 1),
      {threshold: [1]}
);





const Header = ({title, eventId, style}) => {
  const headerRef = useRef();

  const sections = ['premium', 'how-to', 'create-event', 'visit-event'];

  useEffect(() => {
    observer.observe(headerRef.current)

  })
		window.onscroll = function() {
			var scrollPosition = document.documentElement.scrollTop || document.body.scrollTop;

      let found = false;
			Array.prototype.forEach.call(sections, function(id) {
        let e = document.querySelector("#" + id)
				if (e.offsetTop <= scrollPosition + 200 && !found) {
          let link = document.querySelector('a[data-sec="' + id + '"]')
					if (link) { link.classList.add('active') }

          link = document.querySelector('div[data-sec="' + id + '"] a')
					if (link) { link.classList.add('active') }
          found = true;

				} else {
          let link = document.querySelector('a[data-sec="' + id + '"]');
					if (link) { link.classList.remove('active') }

          link = document.querySelector('div[data-sec="' + id + '"] a')
					if (link) { link.classList.remove('active') }
        }
			})
		}

  return (
  <header ref={headerRef}>
		<Navbar expand="lg" style={style} collapseOnSelect={true}>
			<Navbar.Brand href="/"><img src={logo} alt="Vinogreets" title="Vinogreets"/></Navbar.Brand>
      { !!eventId && 
          <a style={{padding: 0}} className="nav-link nav-title mr-auto" href={ "/" + eventId }>{title}</a>}
      { !eventId &&  <>
				<Navbar.Toggle aria-controls="navbar-content" />
				<Navbar.Collapse id="navbar-content">
					<Nav className="mr-auto">
						<Nav.Link data-sec="visit-event" className="active" href="/">Event Besuchen</Nav.Link>
						<Nav.Link data-sec="create-event" href="/#create-event">Event Erstellen</Nav.Link>
						<Nav.Link data-sec="how-to" href="/#how-to">Wie geht's?</Nav.Link>
						<Nav.Link data-sec="premium" href="/#premium">Premium</Nav.Link>
					</Nav>
				</Navbar.Collapse>
			</> }
		</Navbar>

  </header>
  )
}

export default Header
