import React from "react";
import { Link } from "react-router-dom";

const Header = ({title, eventId, vb}) => (
  <footer>
        <div className="vg-container d-flex justify-content-end">
            <Link className="nav-link" to="/legal">Rechtliches</Link>
            <a className="nav-link" href="mailto:frank@sauerburger.com">Kontakt</a>
        </div>
  </footer>
)

export default Header
