import React from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Link } from "react-router-dom";

import zip_icon from "../img/zip.svg"

class DownloadModal extends React.Component {
  onClickClose() {
      this.props.history.push(this.props.backLink)
  }

  render() {
      return (
        <Modal show={true} onHide={() => this.onClickClose()}>
          <Modal.Header>
            <Modal.Title>Foto-Archiv herunterladen</Modal.Title>
          </Modal.Header>

          <Modal.Body className="text-center">
            <a href={ API + `/api/events/${this.props.eventId}/download?auth=${this.props.match.params.zauth}`}>
                <img style={{width: "7rem", height: "auto"}} src={zip_icon} alt="Zip-Datei Icon" title="Zip-Datei" /><br />
                <Button variant="primary">
                    vinogreets.zip
                </Button>
            </a>
          </Modal.Body>


          <Modal.Footer>
            <Link to={this.props.backLink}>
                <Button variant="secondary">Zum Event</Button>
            </Link>
          </Modal.Footer>
        </Modal>
)
  }
}

export default DownloadModal
