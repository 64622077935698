
import React from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Link } from "react-router-dom";
import Cookie from 'js-cookie';

class DeleteModal extends React.Component {
  imageUrl(token) {
    let url = `${API}/api/events/${this.props.eventId}/image/${this.props.match.params.imageId}`
    if (token) {
      url += "?auth=" + token
    }
    return url
  }

  onClickClose() {
      this.props.history.push(this.props.backLink)
  }

  deleteAuthToken() {
    if (this.props.legacy) {
        let query = new URLSearchParams(this.props.location.search)
        return query.get("auth")
    } else {
        return this.props.match.params.dauth
    }
  }

  handleDelete() {
    let headers = {}
    let csrf_token = Cookie.get("csrftoken")
    if (csrf_token) {
      headers["X-CSRFToken"] = csrf_token
    }
    fetch(this.imageUrl(this.deleteAuthToken()),
          {method: "DELETE", headers: headers})
    .then((response) => {
      if (response.ok) {
          this.onClickClose()
          this.props.onDeleted()
      } else {
          this.props.onError(4)
      }
    }).catch((error) => {
        this.props.onError(2)
    });
  }

  render() {
    return (
        <Modal show={true} onHide={() => this.onClickClose()}>
          <Modal.Header>
            <Modal.Title>Foto löschen</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>Möchtest du dieses Foto wirklich löschen?</p>
            <div className="d-flex justify-content-center">
                <img src={this.imageUrl()}
                style={{width: '100%', height: '300px', margin: '1rem', objectFit: 'contain'}} />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Link to={this.props.backLink}>
                <Button variant="secondary">Abbrechen</Button>
            </Link>
            <Button onClick={() => this.handleDelete()} type="submit" variant="outline-primary">Foto löschen</Button>
          </Modal.Footer>
        </Modal>
    )
  }
}

export default DeleteModal
