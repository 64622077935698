
import React from 'react';
import classNames from 'classnames'
import Div100vh from 'react-div-100vh'

class Dropzone extends React.Component {
  constructor(props) {
    super(props)
    this.state = {highlighted: false}
  }

  highlightZone(e) {
    e.preventDefault()
    e.stopPropagation()
    this.setState({highlighted: true})
  }

  unhighlightZone(e) {
    e.preventDefault()
    e.stopPropagation()
    this.setState({highlighted: false})
  }

  handleDrop(e) {
    e.preventDefault()
    e.stopPropagation()
    if (e.dataTransfer.files.length > 0) {
        this.props.onDrop(e.dataTransfer.files[0])
    }
    this.unhighlightZone(e)
  }


  render() {
    return (
    <Div100vh style={{minHeight: '100rvh', position: 'relative', paddingBottom:
    '3rem'}} className={classNames(this.props.className, {dropzone: true, 
                                'dropzone-highlighted': this.state.highlighted})}
        onDragEnter={(e) => this.highlightZone(e)}
        onDragOver={(e) => this.highlightZone(e)}
        onDragLeave={(e) => this.unhighlightZone(e)}
        onDrop={(e) => this.handleDrop(e)} >
      { this.props.children }
    </Div100vh>)
  }
}

export default Dropzone;
