import React from "react";
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { Link } from "react-router-dom";
import classnames from "classnames";

import uploadIcon from '../img/upload.svg';
import emptyIcon from '../img/empty.svg';

const ConditionalWrapper = ({ condition, wrapper, children }) => 
  condition ? wrapper(children) : children;


class Tiling extends React.Component {
  getStoredAuth(image_id) {
    if (this.props.admin) {
        let matches =  this.props.wall.filter(item => item.id == image_id)
        if (!matches) {
            return false
        }
        return matches[0]["delete"]
    } else {
        let delete_links = JSON.parse(window.localStorage.getItem("delete_links"))
        if (!delete_links) {
            return false;
        }
        let stored_link = delete_links[image_id]
        if (!stored_link) {
          return false;
        }

        return stored_link.token
    }
  }

  pinAction(i, id, pin) {
    this.props.onPinAction(i, id, pin)
  }

  render() {
    return (
      <div style={{position: 'relative'}}>
        <div className="vg-container wall-container"><div className={classnames('wall', {'tile-loading': this.props.loading})}>

          { !this.props.loading && 
            <>
              { !this.props.noPlus &&
              <div className="tile">
                <img className="tile-image tile-add"
                     src={uploadIcon}
                     onClick={() => this.props.onClickPlus()}
                     style={{cursor: 'pointer'}}
                     alt="+" />
              </div>
              }

              { this.props.wall.map((item, i) => (
                <div className="tile"
                     key={!!item.full_size ? `thumb-${item.id}` : `spacer-${i}`}
                     style={{position: 'relative'}}>
                  { (this.getStoredAuth(item.id) && !this.props.pin_admin) &&
                    <Link to={`${this.props.match.url}/delete/${item.id}/auth/${this.getStoredAuth(item.id)}`}>
                      <Button className="tile-delete"
                           title="Foto löschen"
                           style={{top: '5px', right: '5px',
                                   padding: 0, cursor: 'pointer',
                                   position: 'absolute', lineHeight: '1',
                                   padding: '1px 7px 2px', fontSize: '24px',
                                   textAlign: 'center', borderRadius: '15px'}}>
                        &times;
                      </Button>
                    </Link>
                  }
                  { (item.pinned && !this.props.pin_admin && !this.props.admin) &&
                      <Button variant="secondary" disabled
                          title="Foto angeheftet durch Gastgeber"
                           style={{top: '5px', left: '5px',
                                   padding: 0, cursor: 'default',
                                   opacity: 1,
                                   position: 'absolute', lineHeight: '1',
                                   padding: '1px 3px 3px', fontSize: '22px',
                                   textAlign: 'center', borderRadius: '50%'}}>
                        <svg className="bi bi-arrow-down-circle" width="1em" height="1em" viewBox="0 0 10 10" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path d="m 3.5506877,0.92552 c -0.2671035,-2.5617e-4 -0.4872515,0.2094513 -0.499963,0.4762523 -0.012711,0.2668011 0.186502,0.4964886 0.45242,0.5216277 0,0 -0.3555338,3.73465 -0.3555338,3.73465 0,0 -0.083199,0 -0.083199,0 -0.1807532,-0.00323 -0.3491768,0.091348 -0.4404995,0.2473681 -0.091323,0.1560204 -0.091323,0.3491834 0,0.5052038 0.091323,0.1560203 0.2597463,0.2506007 0.4404995,0.2473681 0,0 1.6350425,0 1.6350425,0 0,0 0,2.1115 0,2.1115 -0.00138,0.1083637 0.055638,0.2090924 0.1492606,0.263677 0.093622,0.054585 0.20937,0.054585 0.3029923,0 0.093622,-0.054585 0.1506421,-0.1553133 0.1492606,-0.263677 0,0 0,-2.1115 0,-2.1115 0,0 1.6345261,0 1.6345261,0 0.1807532,0.00323 0.3491768,-0.091348 0.4404995,-0.2473681 0.091323,-0.1560204 0.091323,-0.3491834 0,-0.5052038 C 7.2846708,5.7493978 7.1162472,5.6548174 6.935494,5.65805 c 0,0 -0.083199,0 -0.083199,0 0,0 -0.3550172,-3.73414 -0.3550172,-3.73414 0.2688,-0.021924 0.4717885,-0.253057 0.4588214,-0.5224378 C 6.9431322,1.1320915 6.7188818,0.9215242 6.4492188,0.92552 c 0,0 -2.8985311,0 -2.8985311,0" />
                        </svg>
                      </Button>
                  }
                  { (this.props.pin_admin) &&
                      <Button variant="secondary" 
                          title="Foto angeheftet durch Gastgeber"
                          onClick={() => this.pinAction(i, item.id, !item.pinned)}
                          className={classnames({'admin-pin': true, 'pin-pinned': item.pinned || item.pending_pin_action})}
                          style={{top: '5px', left: '5px',
                                   padding: 0,
                                   opacity: (item.pinned || item.pinAction)? "1" : "inherit",
                                   position: 'absolute', lineHeight: '1',
                                   padding: !item.pending_pin_action ? '1px 3px 3px' : '0px 6px 5px',
                                   fontSize: !item.pending_pin_action ? '22px' : '20px',
                                   textAlign: 'center', borderRadius: '50%'}}>
                        { item.pending_pin_action && 
                          <Spinner animation="border" size="sm" />
                        }
                        { !item.pending_pin_action && 
                        <svg className="bi bi-arrow-down-circle" width="1em" height="1em" viewBox="0 0 10 10" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path d="m 3.5506877,0.92552 c -0.2671035,-2.5617e-4 -0.4872515,0.2094513 -0.499963,0.4762523 -0.012711,0.2668011 0.186502,0.4964886 0.45242,0.5216277 0,0 -0.3555338,3.73465 -0.3555338,3.73465 0,0 -0.083199,0 -0.083199,0 -0.1807532,-0.00323 -0.3491768,0.091348 -0.4404995,0.2473681 -0.091323,0.1560204 -0.091323,0.3491834 0,0.5052038 0.091323,0.1560203 0.2597463,0.2506007 0.4404995,0.2473681 0,0 1.6350425,0 1.6350425,0 0,0 0,2.1115 0,2.1115 -0.00138,0.1083637 0.055638,0.2090924 0.1492606,0.263677 0.093622,0.054585 0.20937,0.054585 0.3029923,0 0.093622,-0.054585 0.1506421,-0.1553133 0.1492606,-0.263677 0,0 0,-2.1115 0,-2.1115 0,0 1.6345261,0 1.6345261,0 0.1807532,0.00323 0.3491768,-0.091348 0.4404995,-0.2473681 0.091323,-0.1560204 0.091323,-0.3491834 0,-0.5052038 C 7.2846708,5.7493978 7.1162472,5.6548174 6.935494,5.65805 c 0,0 -0.083199,0 -0.083199,0 0,0 -0.3550172,-3.73414 -0.3550172,-3.73414 0.2688,-0.021924 0.4717885,-0.253057 0.4588214,-0.5224378 C 6.9431322,1.1320915 6.7188818,0.9215242 6.4492188,0.92552 c 0,0 -2.8985311,0 -2.8985311,0" />
                        </svg>
                        }
                      </Button>
                  }
                  <ConditionalWrapper
                      wrapper={children => 
                               <Link to={`${this.props.match.url}/show/${item.id}`}>
                                 {children}
                               </Link>}
                      condition={(!!item.full_size) && (!this.props.noFullSize)}>
                    <img id={"tile-" + item.id}
                         className="tile-image"  
                         onClick={this.props.pin_admin ? (() => this.pinAction(i, item.id, !item.pinned)) : null}
                         src={`${API}${item.preview}`}
                         // onClick={(!!item.full_size && !this.props.noFullSize) ? () => requestFullscreen(document.body) :  () => {}}
                         style={{backgroundColor: item.color,
                                 cursor: ((!!item.full_size &&
                                 !this.props.noFullSize) ||
                                 this.props.pin_admin) ? 'pointer' : '' }} />
                  </ConditionalWrapper>
                </div>))
              }
            </>
          }

          { this.props.loading &&
            [...Array(Math.floor(Math.random() * 10) + 13).keys()].map((x, i) => (
              <div className="tile" key={i}>
                <img className="tile-image"
                     style={{backgroundColor: 'rgba(0,0,0,0.15)'}}
                     src={emptyIcon}/>
              </div>))
          }
        </div></div>
      </div>
    )
  }
}

export default Tiling
