import React from "react";
import Cookie from 'js-cookie';
import queryString from 'query-string'
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';

import Header from "./Header.jsx";
import Footer from "./Footer.jsx";
import Tiling from "./Tiling.jsx";
import DeleteModal from './DeleteModal.jsx';

class EventAdmin extends React.Component {
  constructor(props) {
    super(props)
  this.state = {step: 'loading', title: null, event: {wall: []}, card: "0",
  confirm: ""}
  }

  activateCard(i) {
      this.setState({card: i})
  }

  confirmDeletion() {
    let admin = "?auth=" + this.props.match.params.aauth

    fetch(API + "/api/events/" + this.props.eventId + admin, {method: 'DELETE'})
    .then((r) => {
        if (r.ok) {
          this.acknowlegeDeletion()
        } else {
          this.props.onError(2)
        }
    })
    .catch((error) => {
      this.props.onError(2)
    });
    this.setState({step: 'loading'})
  }

  acknowlegeDeletion() {
      this.setState({step: 'acknowlegeDeletion'})
  }

  getWall(step='loading') {
    let admin = "?auth=" + this.props.match.params.aauth

    fetch(API + "/api/events/" + this.props.eventId + admin)
    .then((r) => {
        if (r.ok) {
          this.handleUpdate(r)
        } else {
          this.props.onError(1)
        }
    })
    .catch((error) => {
      this.props.onError(2)
    });
    this.setState({step: step})
  }

  handleUpdate(response) {
    response.json()
    .then(json => {
      json.wall = json.wall.filter(item => item.full_size)
      this.setState({event: json, step: 'done'})
      this.props.onTitleUpdate(json.title)
    })
    .catch(error => console.log(error))
  }

  componentDidMount() {
    if (!this.props.match.params.aauth) {
      this.props.onError(6)
    }
    this.getWall()
  }

  deleteEvent() {
      this.setState({step: 'confirmDeletion', confirm: ""})
  }

  abortDeletion() {
      this.setState({step: 'done'})
  }

  handleConfirmChange(e) {
      this.setState({confirm: e.currentTarget.value})
  }

  handleTitleUpdate(e) {
    let event = Object.assign({}, this.state.event)
    event.title = e.currentTarget.value
    this.setState({"event": event})
  }

  handleSubmit(e) {
    e.preventDefault()

    let data = {}
    data['title'] = this.state.event.title

    let headers = {}
    let csrf_token = Cookie.get("csrftoken")
    if (csrf_token) {
      headers["X-CSRFToken"] = csrf_token
    }

    let admin = ""
    if (this.props.match.params.aauth) {
        admin = "?auth=" + this.props.match.params.aauth
    } else {
        this.props.onError(7)
    }

    fetch(API + "/api/events/" + this.props.eventId + admin,
          {method: 'PUT',
           body: JSON.stringify(data),
           headers: headers
          })
    .catch((e) => {
      this.props.onError(2)
    })
    .then(response => response.json())
    .then(response => {
      if (response.success) {
         this.getWall()
      } else {
        this.props.onError(7)
      }
    })
    .catch((e) => {
      this.props.onError(7)
    })

    this.setState({step: 'loading'})
  }

  wallLink(rel) {
    if (rel) {
      return `${this.props.backLink}`
    } else {
      return `${location.origin}${this.props.backLink}`
    }
  }

  downloadLink(rel) {
    if (rel) {
      return `${this.props.backLink}/download/${this.state.event.download}`
    } else {
      return `${location.origin}${this.props.backLink}/download/${this.state.event.download}`
    }
  }

  handlePinAction(i, id, pinned) {
    let admin = "?auth=" + this.props.match.params.aauth

    let data = {pinned: pinned}

    fetch(API + "/api/events/" + this.props.eventId + "/image/" + id + admin,
      {method: 'PUT',
       body: JSON.stringify(data)})
    .then((r) => {
        if (r.ok) {
          this.getWall(this.state.step)
        } else {
          this.props.onError(2)
        }
    })
    .catch((error) => {
      this.props.onError(2)
    });

    let event = Object.assign({}, this.state.event);
    event.wall[i].pending_pin_action = true;
    event.wall[i].pinned = pinned;
    this.setState({event: event})
  }

  render() {
      if (this.state.step == 'loading') {
         return this.renderLoading()
      } else if (this.state.step == 'acknowlegeDeletion') {
         return this.renderAckowledge()
      } else {
         return this.renderDone()
      }
  }

  renderLoading() {
    return (
      <div className="vg-container">
        <div className="m-3 text-center">
        <Spinner animation="border" />
        <p className="my-0 py-0 mx-2">Lade Einstellungen...</p>
        </div>
      </div>
      )
  }
  
  renderAckowledge() {
    return (
        <Modal show={true}>
          <Form>
            <Modal.Header>
              <Modal.Title>Auf Wiedersehen</Modal.Title>
            </Modal.Header>
        
            <Modal.Body>
                <p>Dein Event wurde gelöscht.</p>
                <p>Wir hoffen du und deine Freunde hatten viel Spaß
                mit <a href="https://vinogreets.com">vinogreets.com</a>.
                Wenn dir die Fotowand gefallen hat, kannst du gerne bei der
                nächsten Gelegenheit ein neues Event erstellen.</p>
            </Modal.Body>
        
            <Modal.Footer>
              <Link to="/">
                <Button variant="primary">Zur Startseite</Button>
              </Link>
            </Modal.Footer>
          </Form>
        </Modal>
    )
  }

  renderDone() {
    const values = queryString.parse(this.props.location.search)
    return (
      <div className="vg-container">
        <Accordion activeKey={this.state.card}>
          <Card>
              <Accordion.Toggle as={Card.Header} variant="link" eventKey="0"
              className="settings-head" onClick={() => this.activateCard("0")}>
                Links für deine Freunde
              </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <dl className="row">
                  <dt className="col-sm-3">Bilder-Wand</dt>
                  <dd className="col-sm-9">
                    <Link to={this.wallLink(true)}>{this.wallLink()}</Link>
                  </dd>
                    
                  <dt className="col-sm-3">Bilder-Archiv herunterladen</dt>
                  <dd className="col-sm-9">
                    <Link
                    to={this.downloadLink(true)}>{this.downloadLink()}</Link>
                  </dd>
                </dl>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        
          <Card>
              <Accordion.Toggle as={Card.Header} variant="link" eventKey="1"
              className="settings-head" onClick={() => this.activateCard("1")}>
                Grundeinstellung deines Events
              </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
              <Card.Body>
                <Form onSubmit={(e) => this.handleSubmit(e)}>
                  <Form.Group controlId="formEventId" as={Row}>
                    <Form.Label column sm="3">Event Code</Form.Label>
                        <Col sm="9">
                          <Form.Control type="text" disabled onChange={() => {}} value={this.props.eventId || ""} />
                        </Col>
                  </Form.Group>
        
                  <Form.Group controlId="formEmail" as={Row}>
                    <Form.Label column sm="3">Deine E-Mail-Adress</Form.Label>
                        <Col sm="9">
                            <Form.Control type="email" disabled onChange={() => {}} value={this.state.event.email || ""} />
                        </Col>
                  </Form.Group>
        
                  <Form.Group controlId="formTitle">
                    <Form.Label>Title</Form.Label>
                      <Form.Control type="text" onChange={(e) =>
                        this.handleTitleUpdate(e)} value={this.state.event.title} />
                  </Form.Group>
                
                  <Button variant="primary" type="submit">
                    Änderungen speichern
                  </Button>
                </Form>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        
          <Card>
              <Accordion.Toggle as={Card.Header} variant="link" eventKey="2"
              className="settings-head" onClick={() => this.activateCard("2")}>
                  Fotos anheften
              </Accordion.Toggle>
            <Accordion.Collapse eventKey="2"><>
                { !!this.state.event.wall.length && <>
                  <Card.Body>
                      Hefte einzelne Fotos an den Beginn der Wand.
                  </Card.Body>
                  <Tiling eventId={this.props.eventId} match={this.props.match}
                  noFullSize={true}
                  onPinAction={(i, id, pinned) => this.handlePinAction(i, id, pinned)}
                  pin_admin={true} wall={this.state.event.wall} noPlus={true} />
                  </>
                }
                { !this.state.event.wall.length &&
                  <p className="text-muted">Es wurden noch keine Fotos hochgeladen.</p>
                }</>
            </Accordion.Collapse>
          </Card>
        
          <Card border="warning">
              <Accordion.Toggle as={Card.Header} variant="link" eventKey="3"
              className="settings-head text-warning" onClick={() => this.activateCard("3")}>
                  Fotos löschen
              </Accordion.Toggle>
            <Accordion.Collapse eventKey="3"><>
                { !!this.state.event.wall.length && <>
                  <Card.Body>
                      Entferne einzelne Fotos von der Fotowand zu deinem
                      Event.
                  </Card.Body>
                  <Tiling eventId={this.props.eventId} match={this.props.match}
                  noFullSize={true} admin={true} wall={this.state.event.wall} noPlus={true} />
                  </>
                }
                { !this.state.event.wall.length &&
                  <p className="text-muted">Es wurden noch keine Fotos hochgeladen.</p>
                }</>
            </Accordion.Collapse>
          </Card>
        
          <Card border="danger">
              <Accordion.Toggle as={Card.Header} variant="link" eventKey="4"
              className="settings-head text-danger" onClick={() => this.activateCard("4")}>
                  Event löschen
              </Accordion.Toggle>
            <Accordion.Collapse eventKey="4"><>
              <Card.Body>
                <p>Du möchtest dieses Event und alle hochgeladenen Fotos
                löschen? Du und deine Freunde können danach die Fotos nicht mehr
                auf <Link to={this.wallLink(true)}>{this.wallLink()}</Link> anschauen.
                Vergewissere dich, dass du
                eine <Link to={this.downloadLink(true)}>Kopie aller
                Fotos</Link> heruntergeladen hast.</p>
              </Card.Body>
              <Card.Footer className="text-right">
                <Button variant="danger" onClick={() => this.deleteEvent()}>Event löschen</Button>
              </Card.Footer>
            </></Accordion.Collapse>
          </Card>
        
        </Accordion>

        <Modal show={this.state.step == "confirmDeletion"} onHide={() => this.abortDeletion()}>
            <Modal.Header closeButton>
              <Modal.Title>Möchtest du dieses Event löschen?</Modal.Title>
            </Modal.Header>
        
            <Modal.Body>
                <p>Zur Bestätigung, dass du dieses Event wirklich löschen
                möchtest, tippe bitte den Event Code <span style={{fontWeight:
                'bold', fontFamily: 'monospace'}}>{this.props.eventId}</span> in
                das Textfeld.</p>

                <Form.Group controlId="formConfirm">
                  <Form.Control type="text" placeholder="Event code"
                    onChange={(e) => this.handleConfirmChange(e)}
                    value={this.state.confirm}
                    style={{fontFamily: 'monospace'}}
                    isInvalid={this.state.confirm != this.props.eventId}
                    isValid={this.state.confirm == this.props.eventId} />
                </Form.Group>
            </Modal.Body>
        
            <Modal.Footer>
              <Button onClick={() => this.abortDeletion()} variant="secondary">Abbrechen</Button>
              <Button disabled={this.state.confirm != this.props.eventId}
                onClick={() => this.confirmDeletion()}
                variant="outline-primary">Event und alle Fotos löschen</Button>
            </Modal.Footer>
        </Modal>
         
        
        <Route path={`${this.props.match.url}/delete/:imageId/auth/:dauth`}
               render={(props) => <DeleteModal {...props}
                 backLink={this.props.match.url}
                 onError={number => this.props.onError(number)}
                 onDeleted={() => this.getWall()}
                 eventId={this.props.eventId} />
            } />
      </div>
    )
    }
}

export default EventAdmin
