import React from "react";
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Div100vh from 'react-div-100vh'

import Header from "./Header.jsx";
import Footer from "./Footer.jsx";

import card_full from "../img/card_full.jpg";
import card_empty from "../img/card_empty.jpg";

class Legal extends React.Component {
  constructor(props) {
		super(props)
  }

	render() {
		return (
      <Div100vh style={{minHeight: '100rvh', position: 'relative', paddingBottom: '3rem'}} as="main">
		    <Header title="Sammle Fotos von deinem Event" />
		    <div className="vg-container" style={{paddingLeft: "15px", paddingRight: "15px"}}>
<h1 className="display-4">Legal</h1>
<ul>
    <li><a href="#legal-notice">Legal notice</a></li>
    <li><a href="#terms-of-service">Terms of service</a></li>
    <li><a href="#privacy-policy">Privacy policy</a></li>
</ul>

<h2 id="legal-notice">Legal notice</h2>
This website is developed by Chantal Köppel and Frank Sauerburger. The website
is operated by
<address>
Frank Sauerburger<br />
Lameystr. 1<br />
79108 Freiburg
</address>

Phone: +49 7668 3192560<br />
Email: info@sauerburger.com

<h2 id="photo-credits">Photo credits</h2>
<ul>
    <li>Full wine glasses: <a ref="noopener noreferrer nofollow"
    href="https://www.freepik.com/free-photos-vectors/people">People photo
    created by freepik - www.freepik.com</a></li>
    <li>Empty wine glasses: <a ref="noopener noreferrer nofollow"
    href="https://www.freepik.com/free-photos-vectors/light">Light photo created
    by teksomolika - www.freepik.com</a></li>
    <li>Wedding themed icons: <a ref="noopener noreferrer nofollow"
    href="https://de.freepik.com/fotos-vektoren-kostenlos/hochzeit">Hochzeit
    Vektor erstellt von freepik - de.freepik.com</a></li>
		<li>Photos from demo event:
			<ul>
 <li><a ref="noopener noreferrer nofollow" href="https://www.freepik.com/free-photos-vectors/party">Party photo created by freepik - www.freepik.com</a></li>
 <li><a ref="noopener noreferrer nofollow" href="https://www.freepik.com/free-photos-vectors/woman">Woman photo created by master1305 - www.freepik.com</a></li>
 <li><a ref="noopener noreferrer nofollow" href="https://www.freepik.com/free-photos-vectors/music">Music photo created by tirachardz - www.freepik.com</a></li>
 <li><a ref="noopener noreferrer nofollow" href="https://www.freepik.com/free-photos-vectors/people">People photo created by freepik - www.freepik.com</a></li>
 <li><a ref="noopener noreferrer nofollow" href="https://www.freepik.com/free-photos-vectors/food">Food photo created by freepik - www.freepik.com</a></li>
 <li><a ref="noopener noreferrer nofollow" href="https://www.freepik.com/free-photos-vectors/background">Background photo created by freepik - www.freepik.com</a></li>
 <li><a ref="noopener noreferrer nofollow" href="https://www.freepik.com/free-photos-vectors/background">Background photo created by mdjaff - www.freepik.com</a></li>
			</ul>
		</li>

</ul>

<h2 id="terms-of-service">Terms of Service</h2>

The following terms and conditions govern all use of the photo storage service
at <a href={"https://" + window.location.host}>{window.location.host}</a> (the “Website”),
which are owned and operated by <a href="https://sauerburger.com">Frank
Sauerburger</a> (“we” or “us”), including all content, services and support
packages provided via the Website. The Website is offered subject to your
acceptance without modification of all of the terms and conditions contained
herein and all other operating rules, policies (including, without limitation,
procedures that may be published from time to time on this Website by us
(collectively, the “Agreement”).

Please read this Agreement carefully before accessing or using the Website. By
accessing or using any part of the Website, you agree to be bound by the terms
and conditions of this Agreement. If you do not agree to all the terms and
conditions of this Agreement, then you may not access the Website or use any of
the services. If these terms and conditions are considered an offer by us,
acceptance is expressly limited to these terms. The Website is available only to
individuals who are at least 16 years old.

<h3>1. Ownership</h3>

Ownership, copyright, and title of any software that is developed by us
shall at all times remain with us.
You shall not acquire directly,
indirectly or by implication any title, copyright or ownership in the
software, photos, or any parts thereof. We do not claim any ownership rights to the
information that you submit to the Website itself, your photos are
yours.

<h3> 2. Responsibility of Website Visitors</h3>

We have not reviewed, and cannot review, all of the material, including
photos, posted to the Website, and cannot, therefore, be
responsible for that material’s content, use or effects. By operating the
Website, we do not represent or imply that it endorses the material
there posted, or that it believes such material to be accurate, useful or,
non-harmful. You are responsible for taking precautions as necessary to
protect yourself and your computer systems from viruses, worms, Trojan
horses, and other harmful or destructive content. The Website may contain
content that is offensive, indecent, or otherwise objectionable, as well as
content containing technical inaccuracies, typographical mistakes, and other
errors. The Website may also contain material that violates the privacy or
publicity rights, or infringes the intellectual property and other
proprietary rights, of third parties, or the downloading, copying or use of
which is subject to additional terms and conditions, stated or unstated.
We disclaim any responsibility for any harm resulting from the use by
visitors of the Website, or from any downloading by those visitors of
content there posted. You are encouraged to report any violations of our
Acceptable Use requirements to us.

<h3>3. Content Posted on Other Websites</h3>

We have not reviewed, and cannot review, all of the material, including
computer software, made available through the websites and webpages to which
this website links, and that link to this website. We do not have any
control over those external websites and webpages, and are not responsible
for their contents or their use. If links are set by us, we ensure, to an extent
possible to us as a third party, that the
linked content doesn't violate applicable law at the time of setting the
link. By linking to an external website or
webpage, we do not represent or imply that we endorse such website
or webpage.
You are responsible for taking precautions as necessary to
protect yourself and your computer systems from viruses, worms, Trojan
horses, and other harmful or destructive content. We disclaim any
responsibility for any harm resulting from your use of external websites
and webpages.

<h3>4. Copyright Infringement</h3>

As we ask others to respect its intellectual property rights, we
respect the intellectual property rights of others. If you believe that
material located on or linked to by us violates your copyright, you are
encouraged to notify us.

<h3>5. Data Privacy</h3>

You shall ensure that any and all information or data, including without
limitation, personal data, used by you in connection with the Agreement (“User
Data”) is collected, processed, transferred and used in full compliance with
Applicable Data Protection Laws (as defined below) and that it has all obtained
all necessary authorizations and consents from any data subjects to process
User Data. If applicable, you shall adopt and maintain appropriate
organizational, technical and security measures prior to any such collection,
processing or transfer in order to protect against unauthorized access to or
use of User Data. You shall immediately inform us upon becoming aware of
any breach within the meaning of Applicable Data Protection Law relating to
User Data (a “Security Incident”) and to cooperate with us in any
investigation thereof and in the implementation of any measures reasonably
required to be taken in response thereto. If required by Applicable Data
Protection Laws, the parties will enter into standard contractual clauses under
GDPR (as defined below) for the transfer of any User Data outside of the
European Union. For purposes hereof: (a) "Applicable Data Protection Laws"
means any applicable laws, statutes or regulations as may be amended, extended
or re-enacted from time to time which relate to personal data including without
limitation (i) prior to 25 May 2018, the EU Data Protection Directive 95/46/EC
as transposed into EU Member State law; (ii) from and after 25 May 2018, GDPR
and any EU Member State laws implementing the GDPR; and (iii) the e-Privacy
Directive 2002/58/EC, as amended and as transposed into EU Member State law and
any legislation replacing the e-Privacy Directive and (b) "GDPR" means the
Regulation (EU) 2016/679 of the European Parliament and of the Counsel of 27
April 2016 on the protection of natural persons with regard to the processing
of personal data and on the free movement of such data, and repealing Directive
95/46/EC (General Data Protection Regulation).

See also our <a href="#privacy-policy">privacy policy</a>.

<h3>6. Intellectual Property</h3>

This Agreement does not transfer to you any of our other third-party
intellectual property, and all right, title and interest in and to
such property will remain (as between the parties) solely with us.
Trademarks, service marks, graphics and logos used in
connection with the Website may be the trademarks of third parties.
Your use of the Website grants you no right or license to reproduce or
otherwise use any GitLab or other third-party trademarks. 

<h3> 7. Changes</h3>

We reserve the right, at its sole discretion, to modify or replace any
part of this Agreement. It is your responsibility to check this Agreement
periodically for changes. Your continued use of or access to the Website
following the posting of any changes to this Agreement constitutes
acceptance of those changes. We may also, in the future, offer new
services and/or features through the Website (including, the release of new
tools and resources). Such new features and/or services shall be subject to
the terms and conditions of this Agreement. We may also, in the future,
remove features at any time without warning.

<h3> 8. General Representation</h3>

You represent and warrant that (i) your use of the Website will be in strict
accordance with this Agreement and with all applicable laws and regulations
(including without limitation any local laws or regulations in your country,
state, city, or other governmental area, regarding online conduct and
acceptable content, and including all applicable laws regarding the
transmission of technical data exported from the EU or the
country in which you reside) and (ii) your use of the Website will not
infringe or misappropriate the intellectual property rights of any third
party.

<h3> 9. Termination</h3>

We may terminate your access to all or any part of the Website at any
time, with or without cause, with or without notice, effective immediately.
If you wish to terminate this Agreement, you may
simply discontinue using the Website.
All provisions of this Agreement which
by their nature should survive termination shall survive termination,
including, without limitation, ownership provisions, warranty disclaimers,
indemnity and limitations of liability.

<h3> 10. Limitation of Liability</h3>

In no event will we or any of our affiliates, our suppliers or
licensors, be liable with respect to any subject matter of this Agreement
under any contract, negligence, strict liability or other legal or equitable
theory for: (i) any special, incidental or consequential damages; (ii) the
cost of procurement for substitute products or services; (iii) for
interruption of use or loss or corruption of data; or (iv) for any damages
whatsoever. We shall have no liability for any failure or delay due to
matters beyond their reasonable control. The foregoing shall not apply to
the extent prohibited by applicable law.

<h3> 11. Indemnification</h3>

You agree to indemnify and hold harmless us, its affiliates,
contractors, and its licensors, and their respective directors, officers,
employees and agents from and against any and all claims and expenses,
including attorneys' fees, arising out of your use of this Website,
including but not limited to your violation of this Agreement.

<h3> 12. Disclaimer of Warranty</h3>

The Website is provided "as is". We and our affiliates, suppliers and
licensors hereby disclaim all warranties of any kind, express or implied,
including, without limitation, the warranties of merchantability, fitness
for a particular purpose and non-infringement. Neither we nor our
suppliers and licensors, makes any warranty that the Website will be
error-free or that access thereto will be continuous or uninterrupted. You
understand that you download from, or otherwise obtain content or services
through, the Website at your own discretion and risk.

<h3> 13. Partial Invalidity</h3>

If any provision of this document is held by a court of competent
jurisdiction to be invalid, void, or unenforceable, the remaining provisions
shall nevertheless continue in full force without being impaired or
invalidated in any way.

<h3> 14. Failure to Enforce</h3>

The failure of either party to enforce at any time, or for any period of
time, the provisions hereof shall not be construed to be a waiver of such
provisions or of the right of such party to enforce each and every such
provision.

<h3> 15. Governing law</h3>

This Agreement shall be governed by and interpreted in accordance with the
laws of the Federal Republic of Germany.

<hr />

<p>This agreement is based on the <a href="https://about.gitlab.com/terms/">
terms of Use of GitLab.com</a> which is published under a creative commons license
(<a href="https://creativecommons.org/licenses/by-sa/4.0/">CC BY-SA 4.0</a>).
As a
result, this agreement is released under the <a
href="https://creativecommons.org/licenses/by-sa/4.0/">creative commons
Attribution-ShareAlike 4.0 International</a> license.
Your use of this information does not create an
attorney-client relationship between you and us. We are not a law
firm. These policies and procedures may not suit your organization's needs.
Please consult a lawyer if you want to adopt these policies for your own
uses.</p>

<h2 id="privacy-policy"> Privacy Policy</h2>

This privacy policy ("Privacy Policy") applies to all visitors and users ("you") of the
photo storage service at
at <a href={"https://" + window.location.host}>{window.location.host}</a> (“Website”),
which are offered by <a href="https://sauerburger.com">Frank Sauerburger</a> ("we" or "us").
Please read this Privacy Policy carefully. By accessing
or using any part of the Websites, you acknowledge you have been informed of and
consent to our practices with regard to your personal information and data.

<h3>The short version</h3>
<p>We collect your information only with your consent; we only collect the
minimum amount of personal information that is necessary to fulfill the
purpose of your interaction with us; we don't sell it to third parties; and we
only use it as this Privacy Policy describes. The data are processed within the
EU.</p>

<p>Many portions of the Website, including information
you voluntarily provide, will be public-facing to share photos.
While we are committed to open sharing, we strive to respect
the privacy of individual community members and will minimize the information we
collect and share. If you do not want to share your information, including
personally identifiable information, with other community members and the
public, please be thoughtful as to how you interact with the Website and what
information you provide through the Website (for example, by uploading a
photo).</p>

<p>Of course, the short version doesn't tell you everything, so please read on 
for more details!</p>

<h3> What information we collect and why</h3>

<h4> Information from website browsers</h4>
<p>If you're <b>just browsing the website</b>, we collect the same 
basic information that most websites collect. We use common internet 
technologies, such as cookies and web server logs. This is stuff we collect 
from everybody, whether they have an account or not.</p>

<p>The information we collect about all visitors to our website includes the 
visitor's browser type, language preference, referring site, additional 
websites requested, and the date and time of each visitor request. We also 
collect potentially personally-identifying information like Internet Protocol 
(IP) addresses.</p>

<h5> Why do we collect this?</h5>
<p>We collect this information to monitor and protect the security of the
website.</p>

<h4> Information from users who upload a photo or create an event</h4>
<p>If you <b>upload a photo to an existing event, or create a new event</b> ,
we require some basic information at the time of event creation or photo upload.
When you create an event, we require your full name. When you upload a photo, we
process and store the uploaded photo.
You also have the 
option to give us more information if you want to, and this may include your name
when you upload a photo. </p>

<p>"User Personal Information" is any information about one of our
users which 
could, alone or together with other information, personally identify him or 
her. Information such as your name and a
photograph are examples of "User Personal Information."</p>

<p>User Personal Information does not include aggregated, non-personally 
identifying information. We may use aggregated, non-personally identifying 
information to operate, improve, and optimize our website and service.</p>

<h5> Why do we collect this?</h5>
<ul>
 <li>We need your User Personal Information to create an event, and to 
   provide the services you request.
   We use your User Personal Information, specifically your name, to 
   identify you to the Website.</li>
 <li>We use it to fill the photo wall of an event as you request. The photographs are
 accessible by anybody with the access code of an event.</li>
 </ul>

<h4> Bases for Processing Your Information</h4>
<p>Performance of a contract. The use of your information may be necessary to 
perform the contract that you have with us. For example, if you use the Website
and upload a photograph through the Website, or request 
information through the Website, we will use your information to carry out our 
obligation to complete and administer that request.</p>

<p>Legitimate interests. We use your information for our legitimate 
interests, such as to provide you with the best content through the Website
and communications with users and the public, to improve our 
products and services, and for administrative, security, fraud prevention and 
legal purposes.</p>

<h3> What information the Website does not collect</h3>
<p>We do not intentionally collect <b>sensitive personal 
information</b>, such as social security numbers, genetic data, health 
information, or religious information. Although the Website does not request or 
intentionally collect any sensitive personal information, we realize that you 
might upload this kind of information.
If you store any sensitive personal information on our servers, you are
consenting to our storage of that information on our servers, which are in the
EU.</p>

<p>We do not intentionally collect information that is <b>contained in uploaded
photographs</b> or other free-form content inputs. Information uploaded by you 
belongs to you, and you are responsible for it, as well as for 
making sure that your content complies with our <a href="#terms-of-service">Terms
of Service</a>.</p>

<p>Since all photograph collections on this site (events) are in principle
publicly accessible (if the access code is know), anyone (including us) may view its contents. 
If you have upload private or sensitive information, such as email addresses,
that information may be indexed by search 
engines or used by third parties.</p>

<p>If you're a <b>child under the age of 16</b>, you may not upload any photos
or create events on the Website. The Website does not knowingly collect information
from or direct 
any of our content specifically to children under 16. If we learn or have 
reason to suspect that you are a user who is under the age of 16, we will,
unfortunately, have to delete the child's upload. Please see our <a
href="#terms-of-service">Terms of
Service</a> for information about account termination.</p>

<h3> How we share the information we collect</h3>
<p>We only disclose potentially personally-identifying and 
personally-identifying information to those of our employees, contractors, and 
affiliated organizations that (i) need to know that information in order to 
process it on our behalf or to provide services available on the Website,
and (ii) that have agreed not to disclose it to others.</p>

<p>We will <b>not rent or sell</b> potentially personally-identifying and 
personally-identifying information to anyone. Other than to our employees, 
contractors, and affiliated organizations, as described above, we disclose
potentially personally-identifying and personally-identifying information only 
when required to do so by law, or when we believe in good faith that 
disclosure is reasonably necessary to protect the property or rights of us, 
third parties, or the public at large.</p>

<p>We take measures reasonably necessary to protect against the 
unauthorized access, use, alteration, or destruction of potentially 
personally-identifying and personally-identifying information.</p>

<h3> Public Information on our Website</h3>
<p>All of the Website is (in principle) public-facing. Third 
parties may access and use it in compliance with our <a
href="#terms-of-service">Terms of
Service</a>. We do not sell that content; it is yours.
However, we do allow third parties, such as 
research organizations or archives, to compile public-facing 
information.</p>

<p>Your Personal Information, associated with your content, may be gathered by 
third parties in these compilations of data. If you do not want your 
Personal Information to appear in third parties' compilations of data, 
please do not upload Personal Information.</p>

<h3> Cookies</h3>
<p>The Website uses cookies to make interactions with our service easy and 
meaningful. We use cookies (and similar technologies, like HTML5 localStorage) 
to store deletion links, your preferences and protect you and the website from
security threats.</p>

<p>A cookie is a small piece of text that our web server stores on your 
computer or mobile device, which your browser sends to us when you return to 
our site. Cookies do not necessarily identify you if you are merely visiting 
the Website; however, a cookie may store a unique identifier for each logged in 
user. The cookies the Website sets are essential for the operation of the website 
or are used for performance or functionality. 
Visitors who do not wish to have cookies placed on their computers may 
set their browsers to refuse cookies before using the Websites. Disabling 
browser cookies may cause certain features of our websites to not function 
properly.</p>

<h3>How we secure your information</h3>
<p>We take all measures reasonably necessary to protect User Personal 
Information from unauthorized access, alteration, or destruction; maintain data 
accuracy; and help ensure the appropriate use of User Personal Information. We 
follow generally accepted industry standards to protect the personal 
information submitted to us, both during transmission and once we receive 
it.</p>

<p>No method of transmission, or method of electronic storage, is 100% secure. 
Therefore, we cannot guarantee its absolute security.</p>

<h3> Privacy Practices</h3>
<p>Information we collect will be stored and processed in the EU
in accordance with this Privacy Policy. For all Website visitors and users, we
will:</p>
<ul>
 <li> provide clear methods of unambiguous, informed consent when we do 
   collect your personal information;
 </li><li> only collect the minimum amount of personal data necessary for the 
   purpose it is collected for, unless you choose to provide us more;
 </li><li> offer you simple methods of accessing, correcting, or deleting your 
   information that we have collected, with the exception of information you 
   voluntarily provide that is necessary to retain as is for the integrity of our 
   project code as described further below; and
 </li><li> provide Website users notice, choice, accountability, security, and 
   access, and we limit the purpose for processing. We also provide our users a 
   method of recourse and enforcement.
 </li>
</ul>

<p>If you are located in the European Union, you are entitled to the 
following rights with regard to your personal information and data:</p>

<ul>
 <li> Right of access to your personal data, to know what information about 
   you we hold
 </li><li> Right to correct any incorrect or incomplete personal data about 
   yourself that we hold
 </li><li> Right to restrict/suspend our processing of your personal data
 </li><li> Right to complain to a supervisory authority if you believe your 
   privacy rights are being violated
 </li>
</ul>

<p>Additional rights that may apply to you in certain instances:</p>
<ul>
 <li> Right of data portability (if our processing is based on consent and 
   automated means)
 </li><li> Right to withdraw consent at any time (if processing is based on 
   consent)
 </li><li> Right to object to processing (if processing is based on legitimate 
   interests)
 </li><li> Right to object to processing of personal data for direct marketing 
   purposes
 </li><li> Right of erasure of your personal data from our system 
   ("right to be forgotten") if certain grounds are 
   met
 </li>
</ul>

<p>To exercise your privacy rights, you can email us at the address given 
below in the "Contacting us about your privacy" section of this Privacy
Policy.</p>

<h3> Data Retention and Deletion</h3>
<p>If you upload a photograph to the Website, you may access, 
or delete the photograph using the link provided upon upload completion. This
link contains authentification credentials. If you lose the link, we might not
identify you as the authorized person to request deletion.</p>

<p>We may retain certain User Personal Information indefinitely unless you 
delete it or request its deletion. For example, we don’t delete 
photographs, so unless you choose to delete your upload, we will 
might retain your photographs indefinitely.</p>

<h3> Contacting us about your privacy</h3>
If you have questions or concerns about the way we are handling your 
information or would like to exercise your privacy rights, please email us
at <a href="mailto:info@sauerburger.com">info@sauerburger.com</a> or write a letter to

<address>
Frank Sauerburger<br />
Lameystr. 1<br />
79108 Freiburg <br />
Germany
</address>

<p>We will respond within 30 days of receiving your message at the latest but 
please note for promptest response, we recommend emailing us.</p>

<h3> Privacy Policy Changes</h3>
<p>Although most changes are likely to be minor, we may change our 
privacy policy from time to time, and in our sole discretion. We will 
provide notification to users who have provided us email addresses of material 
changes to this Privacy Policy through our website prior to the change taking 
effect by posting a notice on our home page or sending email to the email 
address specified in your account. We encourage visitors to frequently 
check this page for any minor changes to its Privacy Policy. Your continued use 
of this site after any change in this Privacy Policy will constitute your 
acceptance of such change.</p>

<hr />

<p>This privacy statement is based on the <a href="ttps://about.gitlab.com/privacy/">privacy statement
of GitLab.com</a> which is published under a creative commons license
(<a href="https://creativecommons.org/licenses/by-sa/4.0/">CC BY-SA 4.0</a>)
and on the <a href="https://help.github.com/articles/github-privacy-statement/">
privacy statement of GitHub.com</a> which is published under a creative commons license
(<a href="https://creativecommons.org/publicdomain/zero/1.0/">CC0</a>). As a
result, this privacy statement is released under the <a
href="https://creativecommons.org/licenses/by-sa/4.0/">creative commons
Attribution-ShareAlike 4.0 International</a> license.
Your use of this information does not create an
attorney-client relationship between you and us. We are not a law
firm. These policies and procedures may not suit your organization's needs.
Please consult a lawyer if you want to adopt these policies for your own
uses.</p>




		    </div>
		    <Footer />
		  </Div100vh>
		)
		}
}

export default Legal
