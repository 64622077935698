
import React from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Carousel from 'react-bootstrap/Carousel';
import { Link } from "react-router-dom";
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import memoize from "memoize-one";

function requestFullscreen(el) {
  if (el.requestFullscreen) {
    el.requestFullscreen();
  } else if (el.mozRequestFullScreen) {
    el.mozRequestFullScreen();
  } else if (el.webkitRequestFullScreen) {
    el.webkitRequestFullScreen();
  } else if (docElm.msRequestFullscreen) {
    el.msRequestFullscreen();
  }
}

function exitFullscreen() {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.webkitCancelFullScreen) {
    document.webkitCancelFullScreen();
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen();
  }
}

class Slideshow extends React.Component {
  constructor(props) {
      super(props)

      this.body = null
      this.state = {loaded: {}, play: false, fullscreen: false}

      this.carouselRef = React.createRef()
  }

  filter = memoize(wall => wall.filter(item => !!item.full_size))
  reverse = memoize(
    filteredWall => {
        let t = []
        filteredWall.map((item, i) => {
            t[item.id] = i
        })
        return t
    }
  )

  handleSlide(selectedIndex, e) {
    let new_loaded = Object.assign({}, this.state.loaded)
    if (!new_loaded[selectedIndex]) {
        new_loaded[selectedIndex] = true
        new_loaded[selectedIndex + 1] = true
        new_loaded[selectedIndex - 1] = true
        this.setState({loaded: new_loaded})
    }
    let filteredWall = this.filter(this.props.wall)
    this.props.history.push(filteredWall[selectedIndex].id)
  }

  requestFullscreen() {
      requestFullscreen(document.body)
      this.setState({fullscreen: true})
  }

  exitFullscreen() {
      exitFullscreen()
      this.setState({fullscreen: false})
  }

  componentDidMount() {
    this.body = document.querySelector('body');
    disableBodyScroll(this.body)
  }

  componentWillUnmount() {
    enableBodyScroll(this.body);
  }

  handleClose() {
    enableBodyScroll(this.body);
    this.props.history.push(this.props.backLink)
    this.props.onClosed(this.props.match.params.imageId)
    if (this.state.fullscreen) {
        exitFullscreen()
    }
  }
  playSlideShow() {
      this.requestFullscreen()
      this.setState({play: true})
  }

  pauseSlideShow() {
      this.setState({play: false})
  }

  render() {
    let filteredWall = this.filter(this.props.wall)
    let idToIndex = this.reverse(filteredWall)

    let new_loaded = Object.assign({}, this.state.loaded)
    let currentId = idToIndex[this.props.match.params.imageId]
    new_loaded[currentId] = true;
    new_loaded[currentId + 1] = true;
    new_loaded[currentId - 1] = true;

    return (
      <div style={{position: "fixed",
                   top: 0, left: 0,
                   zIndex: 20,
                   boxSizing: "border-box"}}
           className="w-100">
        <div className="vh-100" style={{backgroundColor: '#000'}}>
          <div className="clearfix" />


          {[true, false].map((play) => (this.state.play == play && 
          <Carousel key={"play-" + play} keyboard={true} touch={true}
                interval={play ? 5000 : null}
                pauseOnHover={false}
                pause="false"
                indicators={false}
                activeIndex={idToIndex[this.props.match.params.imageId]}
                onSelect={(i, e) => this.handleSlide(i, e)}>

            { filteredWall.map((item, i) =>
              <Carousel.Item key={ "full-" + i }>
                <div className="d-flex justify-content-center align-items-center"
                      style={{height: '2.3rem', width: '100%', color: "#fff" }}>
                    { item.author && <p className="Bozon" style={{margin: 0}}>{item.author}</p> }
                </div>
                <div className="d-flex justify-content-center align-items-center vg-controls"
                      style={{position: 'absolute', paddingBottom : '2rem',
                      paddingTop: '1rem',
                      top: '2rem', width: '100%', color: "#fff" }}>
                      <Button className="m-1" variant="outline-light"
                      onClick={() => this.handleClose()}>
                        <svg className="bi bi-x" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" d="M11.854 4.146a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708-.708l7-7a.5.5 0 0 1 .708 0z"/>
                          <path fillRule="evenodd" d="M4.146 4.146a.5.5 0 0 0 0 .708l7 7a.5.5 0 0 0 .708-.708l-7-7a.5.5 0 0 0-.708 0z"/>
                        </svg>
                      </Button>

                      { !this.state.fullscreen &&
                        <Button className="m-1" variant="outline-light" onClick={() => this.requestFullscreen()}>
                          <svg className="bi bi-fullscreen" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M1.5 1a.5.5 0 00-.5.5v4a.5.5 0 01-1 0v-4A1.5 1.5 0 011.5 0h4a.5.5 0 010 1h-4zM10 .5a.5.5 0 01.5-.5h4A1.5 1.5 0 0116 1.5v4a.5.5 0 01-1 0v-4a.5.5 0 00-.5-.5h-4a.5.5 0 01-.5-.5zM.5 10a.5.5 0 01.5.5v4a.5.5 0 00.5.5h4a.5.5 0 010 1h-4A1.5 1.5 0 010 14.5v-4a.5.5 0 01.5-.5zm15 0a.5.5 0 01.5.5v4a1.5 1.5 0 01-1.5 1.5h-4a.5.5 0 010-1h4a.5.5 0 00.5-.5v-4a.5.5 0 01.5-.5z" clipRule="evenodd"/>
                          </svg>
                        </Button> }

                      { !!this.state.fullscreen &&
                        <Button className="m-1" variant="outline-light" onClick={() => this.exitFullscreen()}>
                          <svg className="bi bi-fullscreen-exit" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M5.5 0a.5.5 0 01.5.5v4A1.5 1.5 0 014.5 6h-4a.5.5 0 010-1h4a.5.5 0 00.5-.5v-4a.5.5 0 01.5-.5zm5 0a.5.5 0 01.5.5v4a.5.5 0 00.5.5h4a.5.5 0 010 1h-4A1.5 1.5 0 0110 4.5v-4a.5.5 0 01.5-.5zM0 10.5a.5.5 0 01.5-.5h4A1.5 1.5 0 016 11.5v4a.5.5 0 01-1 0v-4a.5.5 0 00-.5-.5h-4a.5.5 0 01-.5-.5zm10 1a1.5 1.5 0 011.5-1.5h4a.5.5 0 010 1h-4a.5.5 0 00-.5.5v4a.5.5 0 01-1 0v-4z" clipRule="evenodd"/>
                          </svg>
                        </Button> }

                      { !this.state.play && 
                        <Button className="m-1" variant="outline-light" onClick={() => this.playSlideShow()}>
                         <svg className="bi bi-play-fill" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                           <path d="M11.596 8.697l-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 010 1.393z"/>
                          </svg>
                        </Button> } 

                      { !!this.state.play && 
                        <Button className="m-1" variant="outline-light" onClick={() => this.pauseSlideShow()}>
                          <svg className="bi bi-pause-fill" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.5 3.5A1.5 1.5 0 017 5v6a1.5 1.5 0 01-3 0V5a1.5 1.5 0 011.5-1.5zm5 0A1.5 1.5 0 0112 5v6a1.5 1.5 0 01-3 0V5a1.5 1.5 0 011.5-1.5z"/>
                          </svg>
                        </Button> } 

                    
                </div>
                <div style={{height: 'calc(100vh - 2rem)', width: '100%'}}>
                  <img style={{height: '100%',
                               width: '100%',
                               objectFit: "contain"}}
                       src={new_loaded[i] ? 
                           `${API}${item.full_size}` :
                           `${API}${item.preview}`
                           }
                       onClick={() => this.handleClose()} />
                </div>

              </Carousel.Item>
            )}
          </Carousel>

          ))}



        </div>
      </div>)
}
}

export default Slideshow
