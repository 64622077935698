
import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import {Helmet} from "react-helmet";
import queryString from 'query-string'
import Div100vh from 'react-div-100vh'

import DeleteModal from './DeleteModal.jsx';
import Slideshow from './Slideshow.jsx';
import Tiling from './Tiling.jsx';
import UploadModal from './UploadModal.jsx';
import Error from './Error.jsx';
import EventAdmin from './EventAdmin.jsx';

import Header from "./Header.jsx";
import Footer from "./Footer.jsx";
import Dropzone from './Dropzone.jsx';
import DownloadModal from './DownloadModal.jsx';

const ConditionalWrapper = ({ condition, wrapper, children, fallback=(x)=>x }) =>
  condition ? wrapper(children) : fallback(children);

class Wall extends React.Component {

  constructor (props) {
    super(props)
    this.body = null;
    this.eventId = this.props.match.params.eventId

    this.dropzoneRef = React.createRef()

    this.state = {"event": {wall: [], title: null}, page: 'loading', error: 0}

    this.getWall()
  }

  setTitle(title) {
    let event = Object.assign({}, this.state.event)
    event.title = title
    this.setState({event: event})

  }  

  getWall() {
    fetch(API + "/api/events/" + this.eventId)
    .then((r) => {
        if (r.ok) {
          this.handleUpdate(r)
        } else {
          this.setState({error: 1})
        }
    })
    .catch((error) => {
      this.setState({error: 2})
    });
  }

  setError(number) {
    this.setState({"error": number})
  }

  handleUpdate(response) {
    response.json()
    .then(json => {
      json.wall.filter(item => item.full_size).map((item, i) => {item.slideIndex = i})
      this.setState({event: json, page: 'wall'})
    })
    .catch(error => console.log(error))
  }

  scrollTo(id) {
    document.querySelector("#tile-" + id).scrollIntoView()
    // exitFullscreen()
  }

  componentDidMount() {
    this.body = document.querySelector('body');
  }

  handleDrop(file) {
    this.setState({uploadFile: file, showUpload: true})
  }

  closeUpload() {
    this.setState({uploadFile: null, showUpload: false})
  }

  openUpload() {
    this.setState({uploadFile: null, showUpload: true})
  }
  onSuccess() {
    this.getWall()
  }

  render() {
    const { eventId } = this.props.match.params
    let delete_links = JSON.parse(window.localStorage.getItem("delete_links"))
    if (!delete_links) {
      delete_links = {}
    }
    return (
    <main>

    <ConditionalWrapper condition={!this.state.event.read_only}
        wrapper={(child) => 
                    <Dropzone className="no-collapse" onDrop={(file) => this.handleDrop(file)} ref={this.dropzoneRef}>
                        {child}
                    </Dropzone>}
        fallback={(child) =>
                    <Div100vh className="no-collapse" style={{minHeight: '100rvh', position: 'relative', paddingBottom: '3rem'}}>
                        {child}
                    </Div100vh>}
    >

    <Header title={this.state.event.title} eventId={this.eventId} style={{flexWrap: 'nowrap'}} />
    <Helmet><title>{ "Vinogreets " + (this.state.event.title || "") }</title></Helmet>

    <Footer vb={true} />

    { this.state.error > 0 &&
      <Error number={this.state.error} eventId={this.props.match.params.eventId} /> }

    { this.state.error == 0 && <>
    <Switch>
      <Route path={`${this.props.match.url}/admin/:aauth`}
             render={(props) => <EventAdmin {...props}
                                  onTitleUpdate={title => this.setTitle(title)}
                                  onError={number => this.setError(number)}
                                  eventId={this.eventId} 
                                  backLink={this.props.match.url} />
                    } />
      <Route path={`${this.props.match.url}`} 
             render={(props) => <Tiling {...props}
                                        eventId={this.eventId}
                                        wall={this.state.event.wall}
                                        noPlus={this.state.event.read_only}
                                        onClickPlus={() => this.openUpload()}
                                        loading={this.state.page == 'loading'} />
                    } />
    </Switch>

      <Route path={`${this.props.match.url}/download/:zauth`} 
             render={(props) => <DownloadModal {...props}
                                  backLink={this.props.match.url}
                                  eventId={this.eventId} />
        } />

      <Route path={`${this.props.match.url}/show/:imageId`}
             render={(props) => <Slideshow {...props}
                                  onClosed={(i) => this.scrollTo(i)}
                                  backLink={this.props.match.url}
                                  wall={this.state.event.wall} />
                    } />

      
      <Route path={`${this.props.match.url}/delete/:imageId/auth/:dauth`}
             render={(props) => <DeleteModal {...props}
                                  backLink={this.props.match.url}
                                  onError={number => this.setError(number)}
                                  onDeleted={() => this.getWall()}
                                  eventId={this.eventId} />
                    } />

      <Route path={`${this.props.match.url}/delete/:imageId`}
             exact
             render={(props) => <DeleteModal {...props}
                                  backLink={this.props.match.url}
                                  legacy={true}
                                  onError={number => this.setError(number)}
                                  onDeleted={() => this.getWall()}
                                  eventId={this.eventId} />
                    } />

      { this.state.showUpload &&
        <UploadModal backLink={this.props.match.url}
                     uploadFile={this.state.uploadFile}
                     onClickClose={() => this.closeUpload()}
                     onError={number => this.setError(number)}
                     onSuccess={() => this.getWall()}
                     eventId={this.eventId} />
      }
    </>}

    </ConditionalWrapper>
  </main>)
  }
}

export default Wall
