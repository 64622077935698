import {toBlob} from 'blueimp-canvas-to-blob'
import React from "react";
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Link } from "react-router-dom";
import downscale from 'downscale';
import Cookie from 'js-cookie';
import { detect } from 'detect-browser';
import loadImage from 'blueimp-load-image'

class UploadModal extends React.Component {

  constructor(props) {
    super(props)
    this.fileInputRef = React.createRef();

    let author = window.localStorage.getItem('author');
    if (!author) {
      author = "";
    }

    this.state = {
      step: 'showPrompt',
      author: author,
    }
  }

  handleSelect(e) {
    let file = e.currentTarget.files[0]
    this.handleFile(file)
  }

  handleFile(file) {
    this.setState({step: 'genPreview'})
    this.generatePreview(file)
  }

  openUploadDialog() {
    this.fileInputRef.current.click()
  }
  componentDidMount() {
    if (!this.props.uploadFile) {
        this.openUploadDialog()
    } else {
      this.handleFile(this.props.uploadFile)
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.uploadFile != this.props.uploadFile) {
      this.handleFile(this.props.uploadFile)
    }
  }
  
  async generatePreview(file) {
    try{
      loadImage(
        file,
        (image) =>
           image.toBlob(blob => {
               let blobUrl = URL.createObjectURL(blob)
               this.setState({
                       uploadFile: blob,
                       step: "showPreview",
                       preview: blobUrl})
           } , 'image/jpeg'),
        {maxWidth: 3000, maxHeight: 3000, canvas: true, meta: true, orientation: true} // Options
      )
    } catch (e) {
      console.log(e)

    }
  }

  startUpload() {
    window.localStorage.setItem('author', this.state.author)

    let formData = new FormData()
    formData.append('user_file', this.state.uploadFile);
    if (this.state.author) {
      formData.append('author', this.state.author);
    }

    let headers = {}
    let csrf_token = Cookie.get("csrftoken")
    if (csrf_token) {
      headers["X-CSRFToken"] = csrf_token
    }
    fetch(API + "/api/events/" + this.props.eventId,
          {method: 'POST',
           body: formData,
           headers: headers
          })
    .catch((e) => {
      this.props.onError(2)
    })
    .then(response => response.json())
    .then(response => {
      if (response.success) {
        let delete_links = {}
        try {
            delete_links = window.localStorage.getItem("delete_links");
            delete_links = JSON.parse(window.localStorage.getItem("delete_links"));
        } catch(e) {
            console.log("Error reading delete links from local storage.")
        }

        if (!delete_links) {
          delete_links = {}
        }
        delete_links[response.id] = {token: response.delete_token,
                                     url: response.full_size}
        window.localStorage.setItem("delete_links",
                                    JSON.stringify(delete_links));
        this.setState({step: 'success',
                       uploadId: response.id,
                       uploadAuth: response.delete_token})
        this.props.onSuccess()

      } else {
        this.props.onError(3)
      }
    }).catch((e) => {
      this.props.onError(3)
    })

    this.setState({step: 'uploading', preview: null, uploadFile: null})
  }

  handleAuthorChange(e) {
      this.setState({author: e.currentTarget.value})
  }


  handleSubmit(e) {
    e.preventDefault();

    if (!this.state.uploadFile || !this.state.preview) {
      return;
    }

    if (!this.state.author && this.state.step != "confirmAnonymous") {
      this.setState({'step': 'confirmAnonymous'})
      return
    }

    this.startUpload();
  }

  delLink() {
      return `${location.origin}${this.props.backLink}/delete/${this.state.uploadId}/auth/${this.state.uploadAuth}`
  }

  render() {
      return (
        <Modal show={true} onHide={() => this.props.onClickClose()}>
          { (this.state.step != "success" && this.state.step != "uploading") && <>
            <Modal.Header closeButton>
              <Modal.Title>Lade ein Foto hoch</Modal.Title>
            </Modal.Header>
        
            <Form onSubmit={(e) => this.handleSubmit(e)} validated={this.state.step=="confirmAnonymous" }>
            <Modal.Body>
              <input type="file" ref={this.fileInputRef} accept="image/*" style={{display: 'none'}} onChange={(e) => this.handleSelect(e)} />
              <div className="m-3 d-flex justify-content-center align-items-center spin-box">
               { this.state.step == "showPrompt" && 
                 <Button onClick={() => this.openUploadDialog()}>Wähle ein Foto</Button>
               }
               { this.state.step == "genPreview" && 
                 <>
                   <Spinner animation="border" />
                   <p className="my-0 py-0 mx-2">Erstelle Vorschau...</p>
                 </>
               }
               { (this.state.step == "showPreview" || this.state.step == "confirmAnonymous" ) && 
                   <img src={this.state.preview} style={{width: '100%', height:
                   '300px', objectFit: 'contain'}} />
               }
              </div>
                <Form.Group controlId="formAuthor">
                  <Form.Control type="text" placeholder="Dein Name (optional)"
                    onChange={(e) => this.handleAuthorChange(e)}
                    value={this.state.author}
                    isInvalid={this.state.step == "confirmAnonymous"}
                    />
                  <Form.Control.Feedback type="valid">
                    Möchtest du deinen Name wirklich nicht angeben?
                  </Form.Control.Feedback>
                </Form.Group>
                <p style={{fontSize:"0.75rem"}}>Hast du die Rechte an diesem Foto
                und bist du damit einverstanden, dass wir dein Foto speichern und
                für alle zugänglich machen, die den Code zu diesem Event kennen?
                (<a href="/legal" target="_blank">Weitere Infos</a>)</p>
            </Modal.Body>
        
            <Modal.Footer>
              <Button onClick={() => this.props.onClickClose()} variant="outline-secondary">Abbrechen</Button>
              <Button 
              disabled={!this.state.uploadFile || !this.state.preview}
              type="submit" variant="primary">Ja, Foto hochladen</Button>
            </Modal.Footer>
            </Form>
          </> }
        
          { this.state.step == "uploading" && <>
            <Modal.Header>
              <Modal.Title>Foto wird hochgeladen</Modal.Title>
            </Modal.Header>
        
            <Modal.Body>
              <div className="d-flex justify-content-center align-items-center">
                <Spinner animation="border" />
                <p className="my-0 py-0 mx-2">Foto wird übertragen...</p>
              </div>
            </Modal.Body>
          </> }
        
          { this.state.step == "success" && <>
            <Modal.Header>
              <Modal.Title>Foto hochgeladen</Modal.Title>
            </Modal.Header>
        
            <Modal.Body>
              <p>Falls du dein hochgeladenes Foto (jetzt oder später) löschen möchtest, rufe folgenden Link
              auf.</p>
              <pre><a href={this.delLink()}>{this.delLink()}</a></pre>
              <p>Der Link wird für eine gewisse Zeit in deinem Browser gespeichert.
              So lange diese Daten verfügbar sind, wird dein Foto mit einem Lösch-Button
              angezeigt. Kopiere den Link und speichere ihn für später, damit du den
              Link nicht verlierst. </p>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={() => this.props.onClickClose()} variant="primary">OK</Button>
            </Modal.Footer>
          </> }
        </Modal>
)
  }
}

export default UploadModal
