import "core-js/stable";
import "regenerator-runtime/runtime";
import 'whatwg-fetch'

import './common.scss'

import Wall from './components/Wall.jsx';
import Home from './components/Home.jsx';
import Legal from './components/Legal.jsx';

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route, Link, Redirect} from "react-router-dom";

import favicon from "./img/favicon.png"
import icon57 from "./img/touch-icon-57.png"
import icon76 from "./img/touch-icon-76.png"
import icon100 from "./img/touch-icon-100.png"
import icon120 from "./img/touch-icon-120.png"
import icon152 from "./img/touch-icon-152.png"
import icon180 from "./img/touch-icon-180.png"
import icon167 from "./img/touch-icon-167.png"



ReactDOM.render(
  <Router>
    <Switch>
      <Route path="/" exact component={Home} />
      <Redirect from="/:url*(/+)" to={window.location.pathname.slice(0, -1)} />
      <Route path="/legal" exact component={Legal} />
      <Route path="/:eventId" component={Wall} />
    </Switch>
  </Router>,
  document.getElementById('app-root')
);
